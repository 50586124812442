/* @import url('https://fonts.googleapis.com/css2?family=Averta+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,800;6..12,900;6..12,1000&display=swap'); */
/*@import './fonts/AvertaDemoPE-Regular.otf';*/
/*@import './fonts/AvertaDemoPE-Regular.otf';
@import './fonts/AvertaDemoPE-ExtraboldItalic.otf';*/
/* @import url('https://fonts.googleapis.com/css2?family=Averta+Sans:opsz,wght@6..12,500&family=Averta:wght@200;300;400;500;600;700;800;900;1000&display=swap'); */

@font-face {
  font-family: 'Quicksand';
  src: url('./fonts/Quicksand-Medium.woff') format('woff');
  src: url('./fonts/Quicksand-Medium.woff2') format('woff2');
}

/* 
@font-face {
  font-family: 'Averta';
  src: url('./v2/assets/font/Averta-Regular.woff') format('woff');
} */

* {
  font-family: 'Quicksand';
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


#content {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }

.your-menu-class {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
}

/* ::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #C7CCD3;
} */

::-ms-reveal {
  display: none;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #C7CCD3;
}

input:focus {
  outline: none;
}


a {
  text-decoration: none;
}

li {
  list-style: none;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_901_11562)"><path d="M9.48682 16.4404C13.5827 16.4404 16.9031 13.1201 16.9031 9.02417C16.9031 4.92828 13.5827 1.60791 9.48682 1.60791C5.39093 1.60791 2.07056 4.92828 2.07056 9.02417C2.07056 13.1201 5.39093 16.4404 9.48682 16.4404Z" stroke="%230C75EB" stroke-width="1.48325" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.48682 4.57422V9.02398L12.4533 10.5072" stroke="%230C75EB" stroke-width="1.48325" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_901_11562"><rect width="17.799" height="17.799" fill="white" transform="translate(0.587402 0.124512)"/></clipPath></defs></svg>');
  font-size: 24px;
  margin-bottom: 12px;
}


textarea:focus {
  outline: none;
}

input[type="search"]::-webkit-search-decoration:hover,
input[type="search"]::-webkit-search-cancel-button:hover {
  cursor: pointer;
}


/* v1 css Styles */

/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Averta:wght@100;200;300;400;500;600;700;800;900&display=swap");


body {
  margin: 0;
  background: #ffffff;
  font-family: "Averta";
  font-style: "normal";

  -webkit-font-smoothing: antialiased;

  -moz-osx-font-smoothing: grayscale;
  min-height: '100vh';
}
code {
  font-family: "Averta";
  font-style: normal;
}

.wizard a {
  background: #efefef;
  display: inline-block;
  margin-right: 5px;
  outline: none;
  padding: 10px 40px 10px;
  position: relative;
  text-decoration: none;
} */

/* .wizard .active {
  background: #ffff;
  color: blue;
} */
/* 
.wizard a:before {
  width: 0;
  height: 0;
  margin-top: 9px;
  border-top: 14px inset transparent;
  border-bottom: 14px inset transparent;
  border-right: 22px solid gray;
  position: absolute;
  content: "";
  top: 0;
  right: 0;
} */

/* .wizard a:after {
  width: 0;
  height: 0;
  border-top: 35px inset transparent;
  border-bottom: 25px inset transparent;
  border-right: 21px solid #efefef;
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  left: -20px;
  right: -20px;
  z-index: 2;
}

.wizard a:first-child:before,
.wizard a:last-child:after {
  border: none;
}

.wizard a.active:after {
  border-left: 21px solid #007acc;
}

.dropbtn {
  background-color: #3498db;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}

/* Input Type Time */

input[type="time"]::-webkit-calendar-picker-indicator {
  /* background: none; */
  /* display: none; */
  cursor: pointer;
  min-width: 3px;
  padding-top: '-30px !important';
}

/* input date styles */
/* input {
  position: relative;
  width: 100%;
  height: 35px;
  background: #ecedef;
  border: none;
  border-radius: 2px;
  font-size: small;
} */

/* input:focus,
textarea:focus,
select:focus {
  outline: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background-position: right;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: auto;
  margin-top: 8px;
  margin-right: 8px;
  color: #c9c7c7;
  background-image: url("./v1/assets/SVG/calendar.svg");
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
  cursor: pointer;
  min-width: 50px;
} */

/* .ribbon {
  position: absolute;
  overflow: hidden;
  width: 175px;
  height: 75px;
}

.ribbon span {
  font-size: 15px;
  color: white;
  text-align: left;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  background: green;
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 22px;
  left: -22px;
}
.without_ampm::-webkit-datetime-edit-ampm-field {
  display: none;
}
.without_ampm{
  height: 30px;
  font-family: "Averta";
  font-style: normal;
  font-size: 12px;
}
input[type=time]::-webkit-clear-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance:none;
  appearance: none;
  margin: -10px; 
}

.loader {
  box-sizing: border-box;
  display: inline-block;
  width: 40px;
  height: 70px;
  border-top: 5px solid #2A3042;
  border-bottom: 5px solid #2A3042;
  position: relative;
  background: linear-gradient(#2A3042 30px, transparent 0) no-repeat;
  background-size: 2px 40px;
  background-position: 50% 0px;
  animation: spinx 5s linear infinite;
}
.loader:before, .loader:after {
  content: "";
  width: 30px;
  left: 50%;
  height: 30px;
  position: absolute;
  top: 0;
  transform: translatex(-50%);
  background: rgba(195, 195, 195, 0.4);
  border-radius: 0 0 20px 20px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 0 0px;
  animation: lqt 5s linear infinite;
}
.loader:after {
  top: auto;
  bottom: 0;
  border-radius: 20px 20px 0 0;
  animation: lqb 5s linear infinite;
}
@keyframes lqt {
  0%, 100% {
    background-image: linear-gradient(#2A3042 40px, transparent 0);
    background-position: 0% 0px;
  }
  50% {
    background-image: linear-gradient(#2A3042 40px, transparent 0);
    background-position: 0% 30px;
  }
  50.1% {
    background-image: linear-gradient(#2A3042 40px, transparent 0);
    background-position: 0% -30px;
  }
}
@keyframes lqb {
  0% {
    background-image: linear-gradient(#2A3042 40px, transparent 0);
    background-position: 0 30px;
  }
  100% {
    background-image: linear-gradient(#2A3042 40px, transparent 0);
    background-position: 0 -30px;
  }
}
@keyframes spinx {
  0%, 49% {
    transform: rotate(0deg);
    background-position: 50% 30px;
  }
  51%, 98% {
    transform: rotate(180deg);
    background-position: 50% 4px;
  }
  100% {
    transform: rotate(360deg);
    background-position: 50% 30px;
  }
} */

.tree {
  --spacing: 1.5rem;
  --radius: 10px;
}

.tree li {
  display: block;
  position: relative;
  margin-top: 12px;
  padding-left: calc(2 * var(--spacing) - var(--radius) - 2px);
}

.tree ul {
  margin-left: calc(var(--radius) - var(--spacing));
  padding-left: 0;
}

/* .tree ul li{
  border-left : 2px solid #ddd;
} */

.tree ul li:last-child {
  border-color: transparent;
}

.tree ul li::before {
  content: '';
  display: block;
  position: absolute;
  top: calc(var(--spacing) / -2);
  left: -2px;
  width: calc(var(--spacing) + 2px);
  height: calc(var(--spacing) + 1px);
  border: solid #ddd;
  border-width: 0 0 2px 2px;
  border-radius: 0px 8px;
}

/* .tree summary{
  display : block;
  cursor  : pointer;
}

.tree summary::marker,
.tree summary::-webkit-details-marker{
  display : none;
}

.tree summary:focus{
  outline : none;
}

.tree summary:focus-visible{
  outline : 1px dotted #000;
} */

/* .tree li::after,
.tree summary::before{
  content       : '';
  display       : block;
  position      : absolute;
  top           : calc(var(--spacing) / 2 - var(--radius));
  left          : calc(var(--spacing) - var(--radius) - 1px);
  width         : calc(2 * var(--radius));
  height        : calc(2 * var(--radius));
  border-radius : 50%;
  background    : #ddd;
}

.tree summary::before{
  z-index    : 1;
  background : #696 url('expand-collapse.svg') 0 0;
}

.tree details[open] > summary::before{
  background-position : calc(-2 * var(--radius)) 0;
} */

scrolling-text-container {
  width: 120px; /* Set a fixed width */
  max-width: 120px; 
  overflow: hidden;
  position: relative;
  text-align: center; /* Center the container */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.scrolling-text {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  animation: scroll-left 3s linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(120px));
  }
}

@keyframes blinking {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}



.driver-popover.driverjs-theme {
  background-color: #ffffff;
  color: #000;
  min-width: '248px';
}

.driver-active-element {
  border: 3px solid  #0C75EB !important;
} 


.driver-popover.driverjs-theme .driver-popover-title {
  font-size: 20px;
}

.driver-popover.driverjs-theme .driver-popover-title,
.driver-popover.driverjs-theme .driver-popover-description,
.driver-popover.driverjs-theme .driver-popover-progress-text {
  color: #000;
}

.driver-popover.driverjs-theme .driver-popover-navigation-btns {
  justify-content: space-between;
  gap: 3px;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-left .driver-popover-arrow {
  border-left-color: #ffffff;

}

.driver-popover.driverjs-theme .driver-popover-arrow-side-right .driver-popover-arrow {
  border-right-color: #ffffff;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-top .driver-popover-arrow {
  border-top-color: #ffffff;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-bottom .driver-popover-arrow {
  border-bottom-color: #ffffff;
}
;